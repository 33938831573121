import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import Navbar from "./Navbar";
import CategoryButton from "./CategoryButton";

export default function Header() {

  return (
    <div className="header">
      <div className="logo">
        <Box>
          <Link to="/news/me">
            <img alt="" src={Logo} />
          </Link>
        </Box>
      </div>
      <div className="tabs">
        <Navbar />
        <CategoryButton />
      </div>
    </div>
  );
}
