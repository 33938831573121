import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function CategoryButton() {
  const navigate = useNavigate();
  const { category } = useParams();
  const { pathname } = useLocation();
  console.log("location...", location);
  console.log("category...", category);
  const newsText = 'news';
  // const viralText = 'virals';
  const videoText = 'videos';
  const objRedirect = {
    'news': '/news/me',
    'virals': '/virals',
    'videos': '/videos'
  }

  const [newsItem, setNewsItem] = useState(newsText);
  const [categoryItem, setCategoryItem] = useState(null);

  useEffect(() => {
    const category = decideCategoryFromLink();
    if (category) {
      setNewsItem(category);
      setCategoryItem(category);
    } else {
      setNewsItem(null);
      setCategoryItem(null);
    }
  }, [pathname]);
  const navigateTo = (category) => {
    setNewsItem(category);
    setCategoryItem(category);
    navigate(objRedirect[category]);
  }

  const decideCategoryFromLink = () => {
    let category = null;
    if (pathname.includes('/news') || pathname === '/') {
      category = 'news';
    }
    if (pathname.includes('/video')) {
      category = 'videos';
    }
    return category;
  }

  return (
    <div className='desktopBtn'>
      <button onClick={() => navigateTo(newsText)} className={`newsIcon ${newsItem === newsText ? 'active' : ''} `}>
        News
      </button>
      <button onClick={() => navigateTo(videoText)} className={`videoIcon ${categoryItem === videoText ? 'active' : ''} `}>
        Videos
      </button>
    </div>
  )
}
