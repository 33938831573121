export default function PostSkeleton() {

  return <div className='post'>
    <div className='topbarPost'>
      <div className='postLogo'></div>
    </div>
    <div className='postContent'>
      <div className='skeleton-loader'></div>
      <div className='skeleton-loader'></div>
      <br />
      <div className="skeleton-loader" style={{ height: '250px' }}></div>
      <br />
      <div className='skeleton-loader'></div>
      <div className='skeleton-loader'></div>
      <div className='skeleton-loader'></div>
      <div className='skeleton-loader'></div>
    </div>
  </div>
}
