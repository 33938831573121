import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import useNews from '../../custom-hooks/news';
import { formatDate } from '../../util';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import SidebarPostSkeleton from './SidebarPostSkeleton';

export default function SidebarPost({ category, title, newsType = 'news' }) {
  const { posts, getNewsData, isLoading } = useNews(2);
  const navigate = useNavigate();
  const categoryClassMapping = {
    'me': 'latestNews',
    'popular': 'mostPopular',
    'videos': 'viralVideos',
    'popular-videos': 'viralVideos',
  }
  useEffect(() => {
    getNewsData(1, category);
  }, [])

  const handleExternalLink = (url) => {
    window.open(url, '_blank');
  };

  const navigateTo = () => {
    if (newsType === 'videos' && category === 'videos') {
      navigate(`/${newsType}`)
    } else {
      navigate(`/${newsType}/${category}`)
    }
  }

  return (
    <>
      {(posts.length > 0 && !isLoading) && <div className='postPopular'>
        <div className={`headBar ${categoryClassMapping[category]}`}>
          {title} {posts.length > 0 && <span className='viewAll cursorPointer' onClick={() => navigateTo()}>View All</span>}
        </div>
        {posts.map((post) => <>
          <div className='popularPosts' >
            <div className='postContent' >
              <div className="cursorPointer" onClick={() => handleExternalLink(post.link)}>{post?.title}</div>
              <div className='space-between-container'>
                <div className='date'>{post?.categoryName}</div>
                <div className='date'>{formatDate(post?.publicationDate)}</div>
              </div>
              {post && post.newsType === 'video' && post.videoLink && <img src={post.image} height="150" width="100%" />}
            </div>
          </div>
        </>)}
        {posts.length === 0 && !isLoading && <div className='no-news'>No {title} to Show </div>}
        {isLoading && <div className='center'><CircularProgress color='primary' /></div>}
        {posts.length > 0 && <div className='viewMorebtn cursorPointer' onClick={() => navigateTo()}>
          Show More
        </div>}
      </div>}
      {(isLoading) && <SidebarPostSkeleton></SidebarPostSkeleton>}
    </>
  )
}

SidebarPost.propTypes = {
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  newsType: PropTypes.string
};
